import React from "react"
import { slideContainer, slider } from "./remote.module.scss"
import io from "socket.io-client"

class Remote extends React.Component {
  constructor(props) {
    super(props)

    this.dragging = false
    this.state = {
      Left: 0,
      Right: 0,
    }
    this.socket = null
    this.roomId = null
  }

  changeValue(which, e) {
    let newValue = +e.target.value
    if (this.state[which] !== newValue && this.socket != null) {

      this.setState({
        [which]: newValue,
      })

      this.socket.emit("valueChange", {
        roomId: this.roomId,
        data: this.state,
      });
    }
    
  }

  parseQueryString = function(queryString) {
    var params = {},
      queries,
      temp,
      i,
      l

    // Split into key/value pairs
    queries = queryString.split("&")

    // Convert the array of strings into an object
    for (i = 0, l = queries.length; i < l; i++) {
      temp = queries[i].split("=")
      params[temp[0]] = temp[1]
    }

    return params
  }

  componentDidMount() {
    let queryString = window.location.search.substring(1)
    let queryData = this.parseQueryString(queryString)
    console.log(queryData)
    this.socket = io({ path: "/api/v1/socket.io" });

    this.socket.on("connect", () => {
      console.log("socket connected")

      // this.socket.on("valueChange", data => {

      //   if (!this.dragging) {
      //     console.log("setting slider value")
      //     this.setState({
      //       data,
      //     })
      //   }
      // })

      this.socket.on("joinedRoom", roomId => {
        console.log("happily joined " + roomId)
        this.roomId = roomId;
      })
      this.socket.emit("joinRoom", queryData.roomId)
    })
  }

  render() {
    return (
      <div className={slideContainer}>
        <input
          className={slider}
          onTouchStart={() => (this.dragging = true)}
          onMouseDown={() => (this.dragging = true)}
          onTouchEnd={() => (this.dragging = false)}
          onMouseUp={() => (this.dragging = false)}
          type="range"
          step="0.05"
          min="-1"
          max="1"
          onChange={e => this.changeValue('Left', e)}
          value={this.state.Left}
        />

        <input
          className={slider}
          onTouchStart={() => (this.dragging = true)}
          onMouseDown={() => (this.dragging = true)}
          onTouchEnd={() => (this.dragging = false)}
          onMouseUp={() => (this.dragging = false)}
          type="range"
          step="0.05"
          min="-1"
          max="1"
          onInput={e => this.changeValue('Right', e)}
          value={this.state.Right}
        />
      </div>
    )
  }
}

export default Remote
